import { AttestationType } from '#app/server/db.server.ts'
import { nft, nftCollectionInfo } from '#types/loreTypes.tsx'
import { PublicKey } from '@solana/web3.js'
import snakecase from 'lodash.snakecase'
import uniq from 'lodash.uniq'
import { CollectionType } from './templates/utils/templateTypes'
import {
	EthereumIcon,
	SolanaIcon,
	BaseIcon,
	ZoraIcon,
	TezosIcon,
	PolygonIcon,
} from '#app/components/icons'
import { type selectOption } from '#app/components/common/dropdown'
import ShapeIcon from '#app/components/icons/ThirdParty/ShapeIcon.js'

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
	condition: any,
	message: string | (() => string),
): asserts condition {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message)
	}
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

export function getVercelDomainUrl() {
	if (import.meta.env.VITE_VERCEL_URL) {
		return `https://${import.meta.env.VITE_VERCEL_URL}`
	} else {
		return 'http://localhost:3000'
	}
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = request.headers.get('X-Forwarded-Proto') ?? 'http'
	return `${protocol}://${host}`
}

export const formatString = (originalString: string) => {
	try {
		// Normalize the string using NFKD form
		let normalizedString = originalString.normalize('NFKD')
		// Use a regular expression to remove combining diacritical marks
		let plainString = normalizedString.replace(/[\u0300-\u036F]/g, '')
		return plainString
	} catch (e) {
		return originalString
	}
}

export const isNumeric = (str: string) => {
	if (typeof str != 'string') return false // we only process strings!
	// @ts-ignore
	return (
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	) // ...and ensure strings of whitespace fail
}

export const removeSpacesFromString = (originalString: string) => {
	try {
		let newString = originalString.replace(/\s+/g, '')
		return newString
	} catch (e) {
		console.error('Error Removing Spaces from string', e)
		return originalString
	}
}

export const doesArrayHaveDuplicates = (array: any[]) => {
	return new Set(array).size !== array.length
}

export const getArrayDuplicates = (array: any[]) => {
	let sorted_arr = array.slice().sort() // You can define the comparing function here.
	// JS by default uses a crappy string compare.
	// (we use slice to clone the array so the
	// original array won't be modified)
	let results = []
	for (let i = 0; i < sorted_arr.length - 1; i++) {
		if (sorted_arr[i + 1] == sorted_arr[i]) {
			results.push(sorted_arr[i])
		}
	}
	return results
}

export const getCollectionBlockchainNamesString = (
	collectionNFTEntries: CollectionType['nfts'] | [],
) => {
	const chains =
		collectionNFTEntries.map(nftEntry =>
			capitalizeFirstLetter(nftEntry.chain),
		) || []
	const uniqueChains = getFlatArrayUnique(chains)
	const chainsString = uniqueChains.toString()
	return chainsString
}

export const getFlatArrayUnique = (array: any[]) => {
	return uniq(array)
}

export const formatMetadataType = (metadataEntryType: string) => {
	let words = metadataEntryType.split(/(?=[A-Z])/)
	words = words.map(
		word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
	)
	const returnedMetadataType = words.join(' ')

	return returnedMetadataType === 'Instagram'
		? 'Instagram post'
		: returnedMetadataType
}

export const getNFTCollectionInfo = (nftData: nft) => {
	try {
		let artist = {
			name: '',
			verified: false,
			src: undefined,
		}
		if (
			nftData &&
			nftData.raw &&
			Array.isArray(nftData.raw.metadata.attributes)
		) {
			let attrArtist = nftData.raw.metadata.attributes.find(
				(attr: any) => attr.trait_type == 'Artist',
			)
			if (attrArtist) {
				artist = {
					name: attrArtist.value,
					verified: false,
					src: undefined,
				}
			}
		}

		return {
			name: nftData.contract.name
				? nftData.contract.name
				: nftData.collection && nftData.collection.name
					? nftData.collection.name
					: nftData.contract.openSeaMetadata &&
						  nftData.contract.openSeaMetadata.collectionName
						? nftData.contract.openSeaMetadata.collectionName
						: undefined,
			artist: artist,
		} as nftCollectionInfo
	} catch (e) {
		console.log(e)
		return {
			name: '',
			artist: {
				name: '',
				verified: false,
				src: undefined,
			},
		}
	}
}

export const mapSigners = (metadataEntry: any) => {
	const signers = []
	if (metadataEntry.template.slug === 'countersign') {
		signers.push({
			wallet: metadataEntry.originalReference.address,
			role: 'Main signer',
			status: 'Completed',
		})
		signers.push({
			wallet: metadataEntry.address,
			role: 'Countersigner',
			status: 'Completed',
		})
	} else {
		signers.push({
			wallet: metadataEntry.address,
			role: 'Main signer',
			status: 'Completed',
		})
	}

	if (metadataEntry.template.slug == 'proofOfExhibition') {
		if (metadataEntry.countersigningAddress) {
			signers.push({
				wallet: metadataEntry.countersigningAddress,
				role: 'Countersigner',
				status: mapStatus(metadataEntry),
			})
		} else {
			if (
				metadataEntry.properties.artistInitiated === undefined ||
				metadataEntry.properties.artistInitiated
			) {
				if (metadataEntry.properties.receiverAddress) {
					signers.push({
						wallet: metadataEntry.properties.receiverAddress,
						role: 'Countersigner',
						status: mapStatus(metadataEntry),
					})
				}
			} else {
				if (metadataEntry.properties.ownerAddress) {
					signers.push({
						wallet: metadataEntry.properties.ownerAddress,
						role: 'Countersigner',
						status: mapStatus(metadataEntry),
					})
				}
			}
		}
	}

	if (
		metadataEntry.template.slug == 'offchainLend' &&
		metadataEntry.properties.ownerAddress
	) {
		signers.push({
			wallet: metadataEntry.properties.ownerAddress,
			role: 'Countersigner',
			status: mapStatus(metadataEntry),
		})
	}

	if (
		metadataEntry.template.slug == 'offchainSale' &&
		metadataEntry.properties.receiverAddress
	) {
		signers.push({
			wallet: metadataEntry.properties.receiverAddress,
			role: 'Countersigner',
			status: mapStatus(metadataEntry),
		})
	}

	return signers
}

export const mapStatus = (metadataEntry: any) => {
	// If there's no provided countersign wallet, return status null
	if (
		metadataEntry.properties.artistInitiated === undefined ||
		metadataEntry.properties.artistInitiated
	) {
		if (!metadataEntry.properties.receiverAddress) return 'Completed'
	} else {
		if (!metadataEntry.properties.ownerAddress) return 'Completed'
	}
	//TODO string comparison can be removed when the BE returns a boolean instead of a string for isReattested
	if (
		(metadataEntry.isReattested === true ||
			metadataEntry.isReattested === 'true') &&
		metadataEntry.properties.countersignRequired === true
	)
		return 'Completed'
	if (
		(metadataEntry.isReattested === true ||
			metadataEntry.isReattested === 'true') &&
		metadataEntry.properties.countersignRequired === false
	)
		return 'Accepted'
	if (
		metadataEntry.isReattested !== true &&
		metadataEntry.properties.countersignRequired === false
	)
		return 'Completed'
	if (
		metadataEntry.isReattested !== true &&
		metadataEntry.properties.countersignRequired === true
	)
		return 'Pending'
	return ''
}

export function isValidEthereumAddress(address: string) {
	const regex = /^(0x)?[0-9a-fA-F]{40}$/
	return regex.test(address)
}

export function isValidSolanaAddress(address: string) {
	const regex = /^[1-9A-HJ-NP-Za-km-z]{44}$/
	return regex.test(address)
}

export async function asyncIsValidSolanaAddress(address: string) {
	try {
		let pubkey = new PublicKey(address)
		let isSolana = await PublicKey.isOnCurve(pubkey.toBuffer())
		return isSolana
	} catch (e) {
		return false
	}
}

export const isMatchingUserAddressForCIDAddress = (
	cidAddress: string,
	userWallets: JwtVerifiedCredential[],
) => {
	if (userWallets.length > 0) {
		for (let i = 0; i < userWallets.length; i++) {
			const currentWallet = userWallets[i]
			// @ts-ignore
			const walletAddress =
				currentWallet.chain === 'eip155'
					? currentWallet.address.toLowerCase()
					: currentWallet.address
			if (
				(currentWallet.chain === 'eip155'
					? cidAddress.toLowerCase()
					: cidAddress) === walletAddress
			) {
				return true
			}
		}
	}
	return false
}

export async function asyncTruncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (!isValidEthereumAddress(str) && (await !asyncIsValidSolanaAddress(str)))
		return str
	if (str.length > 8) {
		return (
			str.substring(0, 4) + '...' + str.substring(str.length - 4, str.length)
		)
	} else {
		return str
	}
}

export const removeEmpty = (obj: any) => {
	let newObj = {}
	Object.keys(obj).forEach(key => {
		// @ts-ignore
		if (obj[key] === Object(obj[key]))
			// @ts-ignore
			newObj[key] = removeEmpty(obj[key])
		// @ts-ignore
		else if (obj[key] !== undefined) newObj[key] = obj[key]
	})

	return newObj
}

export const prepGAProperties = (obj: any) => {
	let newObj = {}
	Object.keys(obj).forEach(key => {
		// @ts-ignore
		if (obj[key] === Object(obj[key]))
			// @ts-ignore
			newObj[snakecase(key)] = prepGAProperties(obj[key])
		// @ts-ignore
		else if (obj[key] !== undefined) newObj[snakecase(key)] = obj[key]
	})

	return newObj
}

export const chainIDMapping = {
	ethereum: 'ETH',
	solana: 'SOL',
	base: 'BASE',
	tezos: 'TEZ',
	zora: 'ZORA',
	polygon: 'MATIC',
	shape: 'SHAPE',
}

export type ChainName =
	| 'ethereum'
	| 'solana'
	| 'base'
	| 'tezos'
	| 'zora'
	| 'polygon'
	| 'shape'

export const chainOptions: selectOption[] = [
	{
		name: 'Ethereum',
		icon: <EthereumIcon />,
		slug: 'ethereum',
		symbol: 'ETH',
		version: '1',
	},
	{
		name: 'Base',
		icon: <BaseIcon />,
		slug: 'base',
		symbol: 'BASE',
		version: '1',
	},
	{
		name: 'Zora',
		icon: <ZoraIcon />,
		slug: 'zora',
		symbol: 'ZORA',
		version: '1',
	},
	{
		name: 'Solana',
		icon: <SolanaIcon />,
		slug: 'solana',
		symbol: 'SOL',
		version: '1',
	},
	{
		name: 'Tezos',
		icon: <TezosIcon />,
		slug: 'tezos',
		symbol: 'XTZ',
		version: '1',
	},
	{
		name: 'Polygon',
		icon: <PolygonIcon />,
		slug: 'polygon',
		symbol: 'MATIC',
		version: '1',
	},
	{
		name: 'Shape',
		icon: <ShapeIcon />,
		slug: 'shape',
		symbol: 'SHAPE',
		version: '1',
	},
]

const chainArray: { name: string; id: string }[] = []
for (const [key, value] of Object.entries(chainIDMapping)) {
	chainArray.push({ name: key, id: value })
}

export const chainNameIDArray = chainArray

export const capitalizeFirstLetter = (val: string) =>
	val.charAt(0).toUpperCase() + val.slice(1)

export const chainDropdownOptionArray = chainArray.map(option => ({
	name: capitalizeFirstLetter(option.name),
	slug: option.name,
}))

export function truncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (
		!isValidEthereumAddress(str) &&
		!isValidSolanaAddress(str) &&
		str.length > 20
	)
		return str.substring(0, 20)
	if (str.length > 8) {
		return (
			str.substring(0, 4) + '...' + str.substring(str.length - 4, str.length)
		)
	} else {
		return str
	}
}

export function lessTruncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (
		!isValidEthereumAddress(str) &&
		!isValidSolanaAddress(str) &&
		str.length > 20
	)
		return str.substring(0, 20)
	if (str.length > 30) {
		return (
			str.substring(0, 15) + '...' + str.substring(str.length - 15, str.length)
		)
	} else {
		return str
	}
}

export function variableTruncateStringInMiddle(str: string, length: number) {
	if (!str) return ''
	if (str.length > length) {
		return (
			str.substring(0, length / 2) +
			'...' +
			str.substring(str.length - length / 2, str.length)
		)
	} else {
		return str
	}
}

export function formatDateToMMMYY(dateString: string) {
	const date = new Date(dateString)
	const options = { year: '2-digit', month: 'short' }
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

	// Extracting the three-letter month abbreviation and two-digit year
	const [month, year] = formattedDate.split(' ')

	return `${month}-${year}`
}

export const getNFTProfileURL = (
	nftData: nft | AttestationType,
	atomicLoreUrl?: string,
	selectedChain?: string,
) => {
	let chain
	let contractAddress
	let tokenId
	let tokenAddress

	try {
		if (
			nftData.references &&
			((nftData.references.entry && nftData.references.entry.chain) ||
				nftData.references.chain)
		) {
			chain = nftData.references.entry
				? nftData.references.entry.chain.name
				: nftData.references.chain.name
			contractAddress = nftData.references.entry
				? nftData.references.entry.nft.contractAddress
				: nftData.references.nft.contractAddress
			tokenId = nftData.references.entry
				? nftData.references.entry.nft.tokenId
				: nftData.references.nft.tokenId
			//TODO should we use token id or token address?
			tokenAddress = nftData.references.entry
				? nftData.references.entry.nft.tokenAddress
				: nftData.references.nft.tokenAddress
			if (tokenAddress) tokenId = tokenAddress
		} else {
			chain = selectedChain ? selectedChain : nftData.chain
			contractAddress = nftData.contract
				? nftData.contract.address
				: nftData.contractAddress
			tokenId = nftData.tokenId
		}

		if (chain && tokenId) {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/nftProfile/${chain}${chain != 'solana' ? '/' + contractAddress : ''}/${tokenId}`
		} else {
			return undefined
		}
	} catch (e) {
		console.log(e)
		return ''
	}
}

export const getMetadataProfileURL = (
	metadata: AttestationType,
	atomicLoreUrl?: string,
) => {
	// Unsigned or malformed entries have no metadata profile URL
	if (!metadata.references || !metadata.references.attestationIPFSCID) {
		return ''
	}

	if (metadata.template.slug === 'revoke') {
		if (metadata?.originalReference?.template?.slug === 'collection') {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/collection/${metadata.references.attestationIPFSCID}`
		}
	}

	if (metadata.template.slug === 'collection') {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/collection/${metadata.references.attestationIPFSCID}`
	}

	const attestationIPFSCID = metadata.references.attestationIPFSCID

	if (!attestationIPFSCID) return ''

	if (metadata.template.slug === 'rollup') {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/rollup/${attestationIPFSCID}`
	}

	if (metadata.references.entry.chain || metadata.references.chain) {
		const chain = metadata.references.entry
			? metadata.references.entry.chain.name
			: metadata.references.chain.name
		const contractAddress = metadata.references.entry
			? metadata.references.entry.nft.contractAddress
			: metadata.references.nft.contractAddress
		const tokenId = metadata.references.entry
			? metadata.references.entry.nft.tokenId
			: metadata.references.nft.tokenId
		const tokenAddress = metadata.references.entry
			? metadata.references.entry.nft.tokenAddress
			: metadata.references.nft.tokenAddress

		if (chain == 'solana') {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/${chain}/${tokenAddress ? tokenAddress : tokenId}/${attestationIPFSCID}`
		} else {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/${chain}/${contractAddress}/${tokenId}/${attestationIPFSCID}`
		}
	}

	if (metadata.references.collection) {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/collection/${metadata.references.collection}/${attestationIPFSCID}`
	} else if (metadata.references.entry.collection) {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/collection/${metadata.references.entry.collection}/${attestationIPFSCID}`
	} else if (
		metadata.references.entry.nft &&
		metadata.references.entry.nft.collection
	) {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/collection/${metadata.references.entry.nft.collection}/${attestationIPFSCID}`
	}
}

export const getCollectionProfileURL = (
	collection: AttestationType,
	atomicLoreUrl?: string,
) => {
	return `${atomicLoreUrl ? atomicLoreUrl : ''}/collectionProfile/${collection.references.entry.nft.collection ? collection.references.entry.nft.collection : collection.references.attestationIPFSCID}`
}

export const getUserProfileURL = (
	userIdOrUsername: string,
	atomicLoreUrl?: string,
) => {
	if (!userIdOrUsername) return ''
	return `${atomicLoreUrl ? atomicLoreUrl : ''}/userProfile/${userIdOrUsername}`
}

export const getShareNFTToXURL = (nft: nft | nft[], atomicLoreUrl: string) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	return `${twitterUrl}?text=${encodeURIComponent(`Check out ${nft.name} at Atomic Lore:`)}&url=${encodeURIComponent(getNFTProfileURL(nft, atomicLoreUrl))}`
}

export const getShareAttestationToXURL = (
	attestation: AttestationType,
	atomicLoreUrl: string,
	nft?: nft | nft[],
) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	return `${twitterUrl}?text=${encodeURIComponent(`Check out this ${formatMetadataType(attestation.template.slug)} ${!nft || Array.isArray(nft) ? '' : ` for ${nft.name}`} at Atomic Lore:`)}&url=${encodeURIComponent(getMetadataProfileURL(attestation, atomicLoreUrl))}`
}

export const getShareUserProfileToXURL = (
	user: { id: string; username: string },
	atomicLoreUrl: string,
) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	return `${twitterUrl}?text=${encodeURIComponent(`Check out ${user.username}'s profile at Atomic Lore:`)}&url=${encodeURIComponent(getUserProfileURL(user, atomicLoreUrl))}`
}

export const scrollToTop = (window: any, elementRef?: any) => {
	try {
		window.scroll({
			top:
				elementRef && elementRef.current
					? elementRef.current?.getBoundingClientRect().top + window.scrollY
					: 0,
			behavior: 'smooth',
		})
	} catch (e) {
		console.error(e)
	}
}
